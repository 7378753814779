<template>
    <tiptap-vuetify
        v-model="content"
        :extensions="extensions"
        :disabled="disabled"/>
</template>

<script>
import 'tiptap-vuetify/dist/main.css'
import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Code,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History
} from 'tiptap-vuetify'

export default {
    name: 'TextBox',
    components: {TiptapVuetify},
    props: {
        text: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            content: '',
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                        levels: [2, 3, 4, 5]
                    }
                }],
                Bold,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
        }
    },
    watch: {
        text() {
            this.content = this.text;
        },
        content(content) {
            this.$emit('input', content)
        }
    }
}
</script>
