<template>
    <admin-dashboard-layout>
        <v-card :loading="loading">
            <v-card-title>
                <v-row>
                    <v-col v-text="content.title"/>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.content.index'}" v-text="'return'"/>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col md="6">
                            <v-text-field label="Title" v-model="content.title" :disabled="loading"/>
                        </v-col>
                        <v-col md="4">
                            <v-text-field label="Title" v-model="content.slug" :disabled="loading"/>
                        </v-col>
                        <v-col md="2">
                            <v-switch label="Active" v-model="content.is_active" :disabled="loading"/>
                        </v-col>
                    </v-row>
                    <v-text-field label="icon" v-model="content.icon" class="mt-4" :disabled="loading"/>
                    <text-box v-model="content.text" :text="content.text" class="mt-4" :disabled="loading"/>
                    <v-text-field label="scopes" v-model="scopes" class="mt-4" :disabled="loading"/>
                    <v-btn @click="save" v-text="'Save'"/>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import Content from "@/models/Content";
import TextBox from "@/components/Form/TextBox";
import { mapMutations } from "vuex";

export default {
    name: 'content-form',
    components: {
        AdminDashboardLayout,
        TextBox
    },
    data() {
        return {
            content: {},
            scopes: '',
            loading: false,
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        ...mapMutations(['showSnackBar']),

        async fetch() {
            this.loading = true;
            try {
                if (this.$route.params.id) {
                    this.content = await Content.find(this.$route.params.id);
                    this.scopes = this.content.scopes?.join(',')
                }
            } catch (e) {
                this.showSnackBar({color: 'error', timeout: 3000, text: e.response?.data?.message || 'Error'})
            } finally {
                this.loading = false;
            }
        },
        async save() {
            this.loading = true;
            this.content.scopes = this.scopes.split(',');
            const content = new Content(this.content)
            try {
                this.content = await content.save();
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Content saved'})
            } catch (e) {
                this.showSnackBar({color: 'error', timeout: 3000, text: e.response?.data?.message || 'Error'})
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>

</style>
